import Header from "./Header";
import { useRecoilValue } from "recoil";

import { countAtom, tokenAtom } from "./Atom";


function Dashboard() {
    //const val = useRecoilValue(countAtom);
    const token = useRecoilValue(tokenAtom);

    console.log(token);
    return (
        <>
            <Header />

            <div>NOSM DASHBAORD {token['emailId']}</div>
        </>

    );
}

export default Dashboard;