import PropTypes from 'prop-types';
import { useState } from 'react';

import { tokenAtom } from './Atom';
import { useRecoilState } from "recoil";


async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_BASE_URL + '/admin/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json());
}


function Login() {

    const [tokn, setToken] = useRecoilState(tokenAtom);

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            emailId: username,
            password
        });
        if (token.token) {
            const getToken = () => {
                const tokenString = sessionStorage.getItem('token');
                const userToken = JSON.parse(tokenString);
                return userToken;
            };

            const saveToken = userToken => {
                sessionStorage.setItem('token', JSON.stringify(userToken));
                setToken(userToken);
            };
            window.location.href = "/";
            saveToken(token);
        } else {
            alert("Wrong username or password, Please re-enter the correct credintials");
        }

    }


    return (
        <div className="page-wrapper full-page">
            <div className="page-content d-flex align-items-center justify-content-center">
                <div className="row w-100 mx-0 auth-page">
                    <div className="col-md-8 col-xl-6 mx-auto">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-6 pe-md-0">
                                    <div className="auth-side-wrapper">

                                    </div>
                                </div>
                                <div className="col-md-6 ps-md-0">
                                    <div className="auth-form-wrapper px-4 py-5">
                                        <a href="/#" className="noble-ui-logo d-block mb-2">
                                            <img src="./assets/images/movesify_logo.svg" alt="" height="40" />
                                        </a>
                                        <h5 className="text-muted fw-normal mb-4">Welcome back! Log in to your account.</h5>
                                        <form className="forms-sample">
                                            <div className="mb-3">
                                                <label htmlFor="userEmail" className="form-label">Email address</label>
                                                <input type="email" className="form-control" onChange={e => setUserName(e.target.value)} id="userEmail" placeholder="Email" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="userPassword" className="form-label">Password</label>
                                                <input type="password" className="form-control" id="userPassword" onChange={e => setPassword(e.target.value)} autoComplete="current-password" placeholder="Password" />
                                            </div>
                                            <div className="form-check mb-3">
                                                <input type="checkbox" className="form-check-input" id="authCheck" />
                                                <label className="form-check-label" htmlFor="authCheck">
                                                    Remember me
                                                </label>
                                            </div>
                                            <div>
                                                <a href="/#" onClick={handleSubmit} className="btn btn-primary me-2 mb-2 mb-md-0 text-white">Login</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

}

export default Login;