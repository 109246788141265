import { Modal, Button, Box, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import Header from "./Header";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "./Atom";
const $ = require('jquery');
$.DataTable = require('datatables.net');


function Leads() {
    return (
        <>
            <Header />
            <LeadsTable />
        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function LeadsTable() {

    const token = useRecoilValue(tokenAtom);
    const [modelData, setModelData] = useState({});


    const [open, setOpen] = useState(false);
    const handleOpen = (data) => {
        setOpen(true)
        console.log("receivedData", data);
        setModelData(data);
    };
    const handleClose = () => setOpen(false);



    const [leads, setLeads] = useState([]);



    useEffect(async () => {
        const data = await fetch(process.env.REACT_APP_BASE_URL + '/admin/getLeads', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token['token'],
            },
        }).then(data => data.json()).then(data => {
            setLeads(data.response);
            // $('#table_id').DataTable();
            $('#table_id').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 50,
                "language": {
                    search: ""
                }
            });

            // $('#table_id').each(function () {
            //     var datatable = $('#table_id');
            //     // SEARCH - Add the placeholder for Search and Turn this into in-line form control
            //     var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
            //     search_input.attr('placeholder', 'Search');
            //     search_input.removeClass('form-control-sm');
            //     // LENGTH - Inline-Form control
            //     var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
            //     length_sel.removeClass('form-control-sm');
            // });
        });

    }, []);

    return (
        <div className="page-wrapper">

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {modelData.customerDetails ? <>
                        <Typography id="modal-modal-title" variant="h5" component="h1">
                            {modelData.customerDetails.customerName} | {modelData.customerDetails.customerMobileNumber} | {modelData.leadStatus}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <strong>Movement Date</strong>
                            <p>{modelData.movementDate ? new Date(modelData.movementDate).toDateString() : ""} - Slot : {modelData.movementSlot.lable}</p>

                            <strong>Lead Generated</strong>
                            <p>{new Date(modelData.createdDate).toString()}</p>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <strong>House Details</strong>
                            <p>{modelData.houseDetails.abbreviation}</p>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <strong>Moving from</strong>
                            <p>{modelData.movingFrom.address}</p>
                            <p>Moving Floor : {modelData.movingFrom.floor} | Lift Available : {modelData.movingFrom.liftAvailable ? "Yes" : "No"}</p>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <strong>Moving to</strong>
                            <p>{modelData.movingTo.address}</p>
                            <p>Moving Floor : {modelData.movingTo.floor} | Lift Available : {modelData.movingTo.liftAvailable ? "Yes" : "No"}</p>
                        </Typography>

                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <strong>Payment Details</strong>
                            <p>Amount : {modelData.payments.advance.amount} - Payment Date : {new Date(modelData.payments.advance.payment_date).toString()}</p>
                            <p>Order ID: {modelData.payments.advance.order_id} | Payment Id : {modelData.payments.advance.payment_id}</p>
                        </Typography>
                    </> : ""}

                </Box>
            </Modal>

            <div className="page-content">

                <nav className="page-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/#">Tables</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Data Table</li>
                    </ol>
                </nav>

                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="table_id" className="table">
                                        <thead>
                                            <tr>
                                                <th>Lead Generated</th>
                                                <th>Generated Date</th>
                                                <th>Movement Date</th>
                                                <th>Contact Name</th>
                                                <th>Customer Email</th>
                                                <th>Customer Number</th>
                                                <th>House Type</th>
                                                <th>Lead Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {leads.map((data) => {
                                                return (
                                                    <tr key={data["_id"]}>
                                                        <td>
                                                            <Button onClick={() => { handleOpen(data) }}>{data["createdDate"]}</Button>
                                                        </td>
                                                        <td>
                                                            {new Date(data["createdDate"]).toLocaleString()}
                                                        </td>
                                                        <td>
                                                            {data["movementDate"] ? new Date(data["movementDate"]).toLocaleString().split(",")[0] + " - " + data["movementSlot"]["time"] : ""}
                                                        </td>
                                                        <td>{data["customerDetails"]["customerName"]}</td>
                                                        <td>{data["customerDetails"]["customerEmailId"]}</td>
                                                        <td>{data["customerDetails"]["customerMobileNumber"]}</td>
                                                        <td>{data["houseDetails"]["size"]}</td>
                                                        <td>{data["leadStatus"]}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    );
}

export default Leads;

