import { atom } from "recoil";

const getToken = () => {
    const tokenString = sessionStorage.getItem('token') || "{}";
    const userToken = JSON.parse(tokenString);
    return userToken;
};

export const repos = atom({
    key: "repos",
    default: {
        daily: [],
        weekly: [],
        monthly: [],
    },
});

export const view = atom({
    key: "view",
    default: "monthly",
});

export const countAtom = atom({
    key: 'countAtomCounter', // unique ID (with respect to other atoms/selectors)
    default: 15, // default value (aka initial value)
});

export const tokenAtom = atom({
    key: 'token', // unique ID (with respect to other atoms/selectors)
    default: getToken(), // default value (aka initial value)
});


