import { useEffect, useState } from "react";
import Header from "./Header";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "./Atom";
const $ = require('jquery');
$.DataTable = require('datatables.net');

function Vendors() {
    return (
        <>
            <Header />
            <VendorTable />
        </>
    )
}


function VendorTable() {

    const token = useRecoilValue(tokenAtom);


    const [vendors, setVendors] = useState([]);

    useEffect(async () => {

        const data = await fetch(process.env.REACT_APP_BASE_URL + '/admin/getVendorsList', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token['token'],
            },
        }).then(data => data.json()).then(data => {
            setVendors(data.response);
            $('#vendorTable').DataTable();

            console.log("It worked")
        });
    }, []);

    return (
        <div className="page-wrapper">

            <div className="page-content">

                <nav className="page-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/#">Tables</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Data Table</li>
                    </ol>
                </nav>

                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-title">Data Table</h6>
                                <p className="text-muted mb-3">Read the <a href="https://datatables.net/" target="_blank" rel="noreferrer"> Official DataTables Documentation </a>for a full list of instructions and other options.</p>
                                <div className="table-responsive">
                                    <table id="vendorTable" className="table">
                                        <thead>
                                            <tr>
                                                <th>Business Name</th>
                                                <th>GST Number</th>
                                                <th>Contact Person</th>
                                                <th>Mobile Number</th>
                                                <th>Email Id</th>
                                                <th>Active</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vendors.map((data) => {
                                                return (
                                                    <tr key={data["_id"]}>
                                                        <td>{data["businessName"]}</td>
                                                        <td>{data["gstNumber"]}</td>
                                                        <td>{data["contactName"]}</td>
                                                        <td>{data["primaryMobileNumber"]}</td>
                                                        <td>{data["emailId"]}</td>
                                                        <td>{data["connected"] ? "Yes" : "No"}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    );
}

export default Vendors;

