import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import About from './components/About';
import Contact from './components/Contact';
import './App.css';
import Login from './components/Login';
import Vendors from './components/Vendors';
import { useRecoilValue } from "recoil";
import { tokenAtom } from './components/Atom';
import Leads from './components/Leads';
import AccessList from './components/AccessList';

function App() {

  // const [tok, setTock] = useRecoilState(tokenAtom);

  const token = useRecoilValue(tokenAtom);


  // const { token, setToken, authData, logout } = useToken();

  if (Object.keys(token).length < 1) {
    return <Login />
  }

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={< Dashboard />}></Route>
        <Route exact path='/login' element={< Login />}></Route>
        <Route exact path='/dashboard' element={< Dashboard />}></Route>
        <Route exact path='/vendors' element={< Vendors />}></Route>
        <Route exact path='/leads' element={< Leads />}></Route>
        <Route exact path='/access' element={< AccessList />}></Route>
        <Route exact path='/about' element={< About />}></Route>
        <Route exact path='/contact' element={< Contact />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
