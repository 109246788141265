

function Contact() {
    return <address>
        You can find us here:<br />
        GeeksforGeeks<br />
        5th & 6th Floor, Royal Kapsons, A- 118, <br />
        Sector- 136, Noida, Uttar Pradesh (201305)
    </address>
}

export default Contact;