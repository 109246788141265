import { useEffect, useState } from "react";
import Header from "./Header";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "./Atom";
const $ = require('jquery');
$.DataTable = require('datatables.net');

function AccessList() {
    return (
        <>
            <Header />
            <AccessListTable />
        </>
    )
}


function AccessListTable() {

    const token = useRecoilValue(tokenAtom);


    const [accessList, setAccessList] = useState([]);



    useEffect(async () => {
        const data = await fetch(process.env.REACT_APP_BASE_URL + '/admin/getAccessList', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token['token'],
            },
            // body: JSON.stringify(credentials)
        }).then(data => data.json()).then(data => {
            setAccessList(data.response);
            $('#table_id').DataTable();

            // $('#table_id').each(function () {
            //     var datatable = $('#table_id');
            //     // SEARCH - Add the placeholder for Search and Turn this into in-line form control
            //     var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
            //     search_input.attr('placeholder', 'Search');
            //     search_input.removeClass('form-control-sm');
            //     // LENGTH - Inline-Form control
            //     var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
            //     length_sel.removeClass('form-control-sm');
            // });
        });
    }, []);

    return (
        <div className="page-wrapper">

            <div className="page-content">

                <nav className="page-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/#">Tables</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Data Table</li>
                    </ol>
                </nav>

                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-title">Leads Data Table</h6>
                                <p className="text-muted mb-3">Read the <a href="https://datatables.net/" target="_blank" rel="noreferrer"> Official DataTables Documentation </a>for a full list of instructions and other options.</p>
                                <div className="table-responsive">
                                    <table id="table_id" className="table">
                                        <thead>
                                            <tr>
                                                <th>Mobile Number</th>
                                                <th>Profile Completed</th>
                                                <th>isVendor</th>
                                                <th>Enable</th>
                                                <th>Supervisor</th>
                                                <th>Verified</th>
                                                <th>Time Stamp</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {accessList.map((data) => {
                                                return (
                                                    <tr key={data["_id"]}>
                                                        <td>{data["mobileNumber"]}</td>
                                                        <td>{data["isProfileCompleted"] ? "Yes" : "No"}</td>
                                                        <td>{data["isVendor"] ? "Yes" : "No"}</td>
                                                        <td>{data["isEnabled"] ? "Yes" : "No"}</td>
                                                        <td>{data["isSupervisor"] ? "Yes" : "No"}</td>
                                                        <td>{data["isVerified"] ? "Yes" : "No"}</td>
                                                        <td>{data["timestamp"]}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    );
}

export default AccessList;

